<template>
  <div class="page " :class="{'events-page' : showEvents}">
    <!-- <div class="flex">
      <img v-if="userInfo.avatarUrl"
           class="avatar"
           mode="widthFix"
           :src="userInfo.avatarUrl">
      <img v-else
           class="avatar"
           mode="widthFix"
           src="../../assets/avatar.png">
      <div class="ml-5 flex-1">
        <div class="fs-18 flex">{{ userInfo.nickName }} <u class="flex-right  fs-12"><a href="https://h5.aikucun.com/p/sGmruGavj.html">使用教程</a></u>

          <div class="p-icon-arrow" />
        </div>
        <div class="fs-12 color-gray flex">
          <div>ID:{{ userInfo.userSerialNo }}</div>
          <div class="label_babel color-blue flex-right flex h-center fs-10">到期：{{userInfo.subscribeExpireDate}}</div>
        </div>
      </div>
    </div> -->
    <div v-if="showEvents" class="modify-bg"></div>
    <div class="card" :class="{'card-index' : showEvents}">
      <div class="flex b">
        账户信息
      </div>
      <div class="flex fs-12 mt-2">
        <div class="color-gray b">爱豆编号</div>
        <div class="flex-right b">{{ userInfo.nickName }}</div>
      </div>
      <div class="flex fs-12 mt-2">
        <div class="color-gray b">ID</div>
        <div class="flex-right b">{{ userInfo.userSerialNo }}</div>
      </div>
      <div class="flex fs-12 mt-2">
        <div class="color-gray b">到期时间</div>
        <div class="flex-right b">{{ userInfo.subscribeExpireDate }}</div>
      </div>
      <div class="divider mt-2 mb-4"></div>
      <div class="flex relative">
        <div class="fs-14 b">托管的微信号</div>
        <u class="color-gray flex-right fs-12"
           @click="logoutWx()">注销</u>
        <!--        <img src="../../assets/index/floating.gif" class="floating" v-if="showFloating" alt="" @click="targetFloat">-->
      </div>
      <div class="flex h-center mt-2 relative">
        <div class="relative">
          <img v-if="robot.robotWxId"
               class="robotAvatar"
               mode="widthFix"
               :src="robot.headImgUrl">
          <img v-else
               class="robotAvatar"
               mode="widthFix"
               src="../../assets/avatar.png">
          <div v-if="robot.robotWxId && robot.state === 0"
               class="live_out flex h-center">
            已离线
          </div>
        </div>

        <div class="b ml-2 flex-left">{{ robot.nickName }}</div>

        <u class="color-primarys fs-14 ml-2"
           v-if="robot.robotWxId && robot.state === 0"
           @click="toIndex()">重新登录</u>

      </div>
      <!-- <div class="fs-10 color-gray mt-2">
        本产品所提供的所有功能均以登陆您的个微号为基础，为确保您正常使用系统功能。请先登陆您的个微号。
      </div> -->
    </div>
    <div class="card mt-2" :class="{'card-index' : showEvents}">
      <div class="flex relative">
        <div class="fs-14 b">托管功能配置</div>
        <!-- <img src="../../assets/icon_right.png"
             class="icon_right flex-right"> -->

        <a href="http://mallrobot-akc.oss-cn-hangzhou.aliyuncs.com/gnsy.mp4"
           class="flex column fixed_btn1"
           v-if="btnShow">
          <img src="../../assets/btn1.png"
               class="btn1"/>
          <!-- <img src="../../assets/icon_close.png"
               class="icon_close"
               @click="btnShow = false" /> -->
        </a>

      </div>
      <div class="flex mt-4">
        <div class="flex column flex-1"
             @click="toWelcom()">
          <img :src="showEvents ? events_logo_welcome : logo_welcome"
               class="icon_logo">
          <div class="fs-12 mt-1 color-gray b">入群欢迎</div>
        </div>
        <div class="flex column flex-1"
             @click="toKeywords()">
          <img :src="showEvents ? events_logo_key : logo_key"
               class="icon_logo">
          <div class="fs-12 mt-1 color-gray b">关键词回复</div>
        </div>
        <div class="flex column flex-1 friend-follow"
             @click="toFriendCircle()">
          <img :src="showEvents ? events_logo_friend : logo_friend"
               class="icon_logo">
          <div class="fs-12 mt-1 color-gray b">朋友圈跟发</div>
        </div>
        <div class="flex column flex-1"
             @click="toQuestion()">
          <img :src="showEvents ? events_logo_question :  logo_question" class="icon_logo">
          <div class="fs-12 mt-1 color-gray b">问卷调研</div>
        </div>
      </div>
    </div>
    <div class="s1 p-card fs-16 mt-2">
      <div class="flex h-between p-3 pointer"
           @click="show=true">
        <div class="fs-14 b">素材采集对象配置</div>
        <!-- <div class="p-icon-arrow" /> -->

        <div class="color-gray fs-12 ml-1 flex">
          <img src="../../assets/i-orange.png"
               class="i_remind"/>
          <div>说明与建议</div>
        </div>
      </div>
      <div class="ml-2 mr-2">
        <div class="divider"></div>
        <div class="flex item border-top  pb-3">
          <img
              :src="collectionObjectData.groupHeadImgUrl ? collectionObjectData.groupHeadImgUrl : require('../../assets/avatar.png')"
              class="avatar mr-2">
          <div class="wd_all text-flow">
            <div class="fs-14 flex"
                 @click="toGather()">
              <div class="fs-14 b text-flow">{{ collectionObjectData.groupName || '暂未设置' }}</div>

              <div class="color-primary flex-right fs-12">{{
                  collectionObjectData.groupName ? '更改' : '设置采集对象'
                }}
              </div>
              <img src="../../assets/arrow.png"
                   class="icon_arrow ml-1"/>
            </div>
            <div class="mt-1 fs-12 text-flow color-gray w_240"
                 v-if="collectionObjectData.objectName">对象：{{ collectionObjectData.objectName || '未命名' }}
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="flex">
        <div class="fs-14 b">我托管的群</div>
        <div v-if="groupList.length > 0">（{{ groupList.length }}）</div>
        <button v-if="groupList.length > 0"
                class="btn small flex-right"
                @click="toOpenGroup()">
          添加群
        </button>
      </div>
      <div v-if="groupList.length === 0"
           class="flex h-center column mt-2"
           @click="toOpenGroup()">
        <img src="../../assets/no/no_group.png"
             class="no_group">
        <button class="btn mt-2">立即托管</button>
      </div>
      <div v-if="groupList.length > 0"
           class="group_box">
        <div v-for="item in groupList"
             :key="item.chatroomId"
             class="item flex">
          <img :src="item.groupHead"
               class="gAvatar mr-2">
          <div class="flex mt-3 flex-1 border-bottom">
            <div class="flex-1">
              <div class="flex">
                <span class="fs-14 b text-flow flex-1">{{ item.groupName }}</span>
                <span class="mr-2 color-danger" :class="item.followLiveState === 1 ? 'color-danger' : 'color-gray' ">
	                {{ item.followLiveState === 1 ? '跟发中' : '未跟发' }}
                </span>
                <van-switch v-model="item.checked" size="18px" @click="itemChange(item)"/>
              </div>
              <div class="p2 flex" @click="toSelect(item.chatroomId)">
                <span class="mr-2 color-gray">店长：{{ item.shopownerNickName }} </span>
                <img src="../../assets/icon_edit.png" class="icon_delete">
              </div>
            </div>
            <div class="divider_vertical ml-1"></div>
            <div class="pl-2"
                 @click="deleteGroup(item)">
              <img src="../../assets/icon_delete.png"
                   class="icon_delete">
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="logoutDialog"
                 bind:click="onClickHide">
      <div class="popupRemind">
        <div class="popup_remind_txt">
          <span>微信号注销后，微信相关的设置</span>
          <span class="color-danger">（入群欢迎语、群发、关键词回复、采集对象设置）</span>
          <span>将会清除，确定注销？</span>
        </div>
        <div class="bottom_btn flex">
          <div class="bottom_btn_cancel flex h-center"
               @click="ensureLogout(false)">
            取消
          </div>
          <div class="bottom_btn_ensure flex h-center"
               @click="ensureLogout(true)">
            确定
          </div>
        </div>
      </div>
    </van-overlay>

    <van-popup v-model:show="show"
               style="width: 75%"
               class="flex column pl-3 pr-3">
      <div class="fs-14 b mt-4">说明与建议</div>
      <div class="fs-12 mt-3 color-gray">1.采集到的商品链接将自动替换为店主链接推送到跟发群，暂时不支持小程序的转链</div>
      <div class="fs-12 mt-1 color-gray mt-3">
        2.转发过程中，若遇到采集对象一次性批量群发图片可能会出现个别图片的顺序混乱现象，建议与采集对象提前沟通，增加不同品次的商品间隔时间，避免出现多个品次商品乱序现象发送
      </div>
      <div class="fs-12 mt-1 color-gray mt-3 color-danger">
        3.系统暂不支持大于2M的图片转码，若采集图片大小大于2M则原图转发，建议与采集对象提前沟通
      </div>
      <button class="mt-8 mb-4 reflesh b"
              @click="show=false">我知道了
      </button>
    </van-popup>

    <!--		<van-dialog v-model:show="activeShow" @confirm="confirmActive">-->
    <!--			<img src="../../assets/akc_activity.jpg" class="activity "/>-->
    <!--		</van-dialog>-->
    <!--		<PopupRemind v-model="activeShow" :img-url="imgUrl" @confirm="confirmActive"/>-->

    <!--风控弹窗-->
    <risk-management v-model="riskManagementShow" title="通知" :today-date="todayDate" @confirm="riskManagementActive"/>

    <!--定时类型---提示弹窗-->
    <notice-dialog v-model:show="maintenanceNoticeShow" title="机器人春节活动来啦‼" @confirm="regularTimeActive"
                   confirmButtonText="了解详情"/>
    <!--只弹一次---提示弹窗-->
    <once-notice-dialog v-model:show="onceNoticeShow" title="通知" @confirm="onceNoticeActive"
                        confirmButtonText="确定"/>
    <!--到期提醒-->
    <DueReminder v-model:show="dueReminderShow"></DueReminder>

    <!--		<van-popup
            v-model:show="floatPopup"
            closeable
            position="bottom"
            :style="{ height: '100vh' }"
        >
          <img src="../../assets/index/active-new.jpg" class="floating-active" alt="">
        </van-popup>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {Toast} from 'vant'
import {ApiGetTeacher} from '@/apis/FollowLive'
import {defineAsyncComponent} from 'vue'
import {dateFormat} from '../../utils'

export default {
  name: 'Index',
  components: {
    NoticeDialog: defineAsyncComponent(() => import('./TheNoticeDialog.vue')),
    OnceNoticeDialog: defineAsyncComponent(() => import('./TheOnceNoticeDialog.vue')),
    RiskManagement: defineAsyncComponent(() => import('./TheRiskManagement.vue')),
    DueReminder: defineAsyncComponent(() => import('./TheDueReminder')),
    PopupRemind: defineAsyncComponent(() => import('../../components/PopupRemind'))
  },
  data() {
    return {
      logo_welcome: require('../../assets/logo_welcome.png'),
      logo_key: require('../../assets/logo_key.png'),
      logo_friend: require('../../assets/logo_friend.png'),
      logo_question: require('../../assets/logo_question.png'),
      events_logo_welcome: require('../../assets/events/logo_welcome.png'),
      events_logo_key: require('../../assets/events/logo_key.png'),
      events_logo_friend: require('../../assets/events/logo_friend.png'),
      events_logo_question: require('../../assets/events/logo_question.png'),

      // 提示弹框 弹一次
      onceNoticeShow: false,
      floatPopup: false,
      // 维护通知
      maintenanceNoticeShow: false,
      // 风控弹窗 3天弹一次
      riskManagementShow: false,
      imgUrl: require('../../assets/bg--frined-back.png'),
      loading: false,
      robot: {
        nickName: '加载中'
      },
      pageIndex: 1,
      pageSize: 1000,
      groupList: [],
      show: false,
      logoutDialog: false,

      checked: true,
      timeControls: '',
      wxType: 1,

      btnShow: true,
      dueReminderShow: false
    }
  },
  created() {
    // 定时弹窗
    this.regularTimeJudge()
    this.onceNoticeJudge()
    const _this = this
    _this.initData()
    _this.getGather()
    // 判断是否过期 续费弹窗
    this.judgeTime()
    // 风控弹窗
    if (this.riskManagementJudge()) {
      this.riskManagementShow = true
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'collectionObjectData']),
    todayDate() {
      return dateFormat(new Date().getTime(), 'yyyy/MM/dd')
    },
    showFloating() {
      const startTime = new Date(2023, 0).getTime(),
          endTime = new Date(2023, 0, 16).getTime()
      return Date.now() > startTime && Date.now() < endTime
    },
    showEvents() {
      const startTime = new Date(2023, 2, 7).getTime(),
          endTime = new Date(2023, 2, 9).getTime()
      return Date.now() > startTime && Date.now() < endTime
    },
  },
  destroyed() {
    clearInterval(this.timeControls)
  },
  watch: {
    '$route': {
      handler(route) {
        const that = this
        clearInterval(that.timeControls)
      }
    }
  },

  methods: {
    // 定时弹窗
    regularTimeJudge() {
      const formatDate = dateFormat(Date.now(), 'yyyy-MM-dd')
      // 能够定时弹窗
      const dateArray = ['2023-01-06', '2023-01-07', '2023-01-08']
      if (dateArray.includes(formatDate) && !dateArray.includes(localStorage.getItem('akc:regularTime'))) {
        this.maintenanceNoticeShow = false
      }
      localStorage.setItem('akc:regularTime', formatDate)
    },
    // 定时类型弹窗确定
    regularTimeActive() {
      window.location.href = 'https://doc.tzmessage.cn/p/kBvmCxlf9.html'
    },
    // 风控弹窗 3天一次
    riskManagementJudge() {
      if (localStorage.getItem('akc:frequencyTime')) {
        let localTime = new Date(localStorage.getItem('akc:frequencyTime')).getTime()
        const todayTime = new Date(this.todayDate).getTime()
        return todayTime - localTime >= 3 * 24 * 60 * 60 * 1000
      } else {
        return true
      }
    },
    // 风控弹窗确认
    riskManagementActive() {
      localStorage.setItem('akc:frequencyTime', this.todayDate)
      this.riskManagementShow = false
    },
    // 单次弹窗
    onceNoticeJudge() {
      if (localStorage.getItem('akc:onceNotice') !== 'active') {
        this.onceNoticeShow = true
      }
      localStorage.setItem('akc:onceNotice', 'active')
    },
    // 单次弹窗
    onceNoticeActive() {
      this.$router.push('/friendCircle')
    },
    // 判断时间是否过期
    judgeTime() {
      // 当前时间
      const nowDate = new Date().getDate()
      // 本地存放的续费登录时间
      const LoginTime = localStorage.getItem('ack:LoginTime')
      // 如果本地有续费登录时间参数 并且续费登录时间参数等于当前日期 就直接返回
      if (LoginTime && Number(LoginTime) === nowDate) return
      const date = this.userInfo.subscribeExpireDate ? this.userInfo.subscribeExpireDate.split('-') : undefined
      // 此时代表第一次进入没有时间
      if (!date) {
        this.dueReminderShow = true
        localStorage.setItem('ack:LoginTime', nowDate + '')
        return
      }
      const expireDate = new Date(date[0], date[1] - 1, date[2])
      const diffDay = (expireDate.getTime() - new Date().getTime()) / 1000 / 3600 / 24
      if (diffDay <= 5) {
        localStorage.setItem('ack:LoginTime', nowDate + '')
        this.dueReminderShow = true
      }
    },
    targetFloat() {
      window.location.href = 'https://doc.tzmessage.cn/p/kBvmCxlf9.html'
    },
    async initData() {
      const _this = this
      _this.longGet()
      await _this.getData()
      _this.getGroup()
    },
    getData() {
      const _this = this
      return new Promise((resolve, reject) => {
        _this.$post('/WxRobot/GetPorts', {wxType: _this.wxType})
            .then((res) => {
              _this.robot = res[0]
              _this.$store.dispatch('setRobotInfo', _this.robot)
              if (!_this.robot.nickName) {
                Dialog.alert({
                  title: '网络超时',
                  message: '网络连接超时,请重新进入'
                })
              }
              if (!_this.robot.robotWxId) {
                _this.$router.replace('/')
              }
              resolve()
            })
            .catch(() => {
              reject()
            })
      })
    },
    longGet() {
      let _this = this
      _this.timeControls = setInterval(() => {
        _this.getData()
      }, 30000)
    },
    getGroup() {
      const _this = this
      _this.$post('/WxRobot/QueryChatrooms', {
        pageIndex: _this.pageIndex,
        pageSize: _this.pageSize,
        sortName: '',
        sortOrder: 0,
        robotWxIds: [_this.robot.robotWxId],
        likeName: '',
        openState: 2,
        openType: 3
      })
          .then((res) => {
            _this.groupList = res.dataList
            _this.groupList.map(item => {
              // item.checked = item.pushState === 1
              item.checked = item.followLiveState === 1
            })

          })
    },
    async getGather() {
      const _this = this
      const res = await ApiGetTeacher()
      console.log(res)
      if (res) {
        _this.$store.commit('setCollectionObjectData', res)
      } else {
        _this.$store.commit('resetCollectionObjectData')
      }
    },
    toAccount() {
      const _this = this
      if (_this.robot.state == '0') {
        _this.$router.push('/login/index')
      } else {
        _this.$Toast('您是在线的，无需重复登录')
      }

    },
    toIndex() {
      const _this = this
      _this.$router.push('/login/login')
    },
    toOpenGroup() {
      const _this = this
      _this.$router.push('/group/openGroup')
    },
    toWelcom() {
      const _this = this
      if (_this.groupList.length == 0) {
        this.$Toast('请先托管群后再设置入群欢迎语！')
        return
      }
      _this.$router.push('/welcomeWords')
    },
    toKeywords() {
      const _this = this
      if (_this.groupList.length == 0) {
        this.$Toast('请先托管群后再设置关键词回复！')
        return
      }
      _this.$router.push('/keywords')
    },
    toFriendCircle() {
      const _this = this
      _this.$router.push('/friendCircle')
    },
    toQuestion() {
      window.location.href = 'https://www.wjx.cn/vj/Oe3ZuLh.aspx'
    },
    todayPushGoods() {
      const _this = this
      _this.$router.push('/todayPushGoods')
    },
    toGather() {
      const _this = this
      _this.$router.push('/gather')
    },
    toSelect(chatroomId) {
      const _this = this
      _this.$router.push({
        path: '/selectShopManagerGather',
        query: {chatroomId: chatroomId}
      })
    },
    logoutWx() {
      const _this = this
      _this.logoutDialog = true
    },
    onClickHide() {
    },
    async ensureLogout(value) {
      const _this = this
      if (value) {
        const toast1 = Toast.loading({
          message: '注销中...',
          forbidClick: true,
          loadingType: 'spinner'
        })
        await _this.$post('/WxRobot/WxRobotLogout', {
          robotWxId: _this.robot.robotWxId,
          deviceId: _this.robot.deviceId,
          wxType: 0,
          reason: [
            {
              reasonType: 1,
              reasonData: '默认'
            }
          ]
        })
            .then((res) => {
              toast1.clear()
            })
            .catch(err => {
              toast1.clear()
            })
        _this.$router.replace({path: '/login/index', query: {isLogin: true}})
      }
      _this.logoutDialog = false
    },
    itemChange(item) {
      const _this = this
      let way = '/FollowLive/SetFollowLiveChatroom'
      if (item.followLiveState === 1) {
        way = '/FollowLive/RemoveFollowLiveChatroom'
      }
      _this.$post(way, {
        robotWxId: item.robotWxId,
        chatroomId: item.chatroomId
      })
          .then((res) => {
            item.followLiveState = item.followLiveState === 1 ? 0 : 1
            _this.$Toast('切换成功')
          })
          .catch(err => {
            item.checked = !item.checked
          })
    },
    async deleteGroup(item) {
      const _this = this
      await _this.$post('/WxRobot/CloseChatrooms', [{
        chatroomId: item.chatroomId,
        robotWxId: item.robotWxId
      }])
          .then((res) => {
            _this.$Toast('移除成功')
          })
      _this.getGroup()
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";

.notice-box {
  li {
    list-style: decimal inside;
  }
}

.floating {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: .5rem;
  height: .5rem;
}

.floating-active {
  width: 100%;
}

.friend-follow {
  overflow: unset;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 25px;
    top: -10px;
    right: 8px;
    height: 25px;
    background: url("../../assets/index/logo_recommend.png") no-repeat;
    background-size: contain;

  }
}

.events-page {
  background: linear-gradient(0deg, #F7ADBB 0%, #FFE5EC 49%, #FCD9E2 100%);
  position: relative;

  .card-index {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    background: url("../../assets/events/events-bg.png") no-repeat;
    left: 0;
    right: 0;
    top: 0;
    height: 378px;
    background-size: cover;
  }

  .modify-bg {
    z-index: 2;
    position: absolute;
    width: 240px;
    top: 0;
    right: 0;
    height: 333px;
    background: url("../../assets/events/events-modify-bg.png") no-repeat;
    background-size: cover;
    pointer-events: none;

  }

}

</style>
